<template>
  <div class="popupBox">
    <input class="popupInput" type="text" placeholder="Field name" v-model="fieldName"/>
    <select v-model="fieldType" required>
      <option value="undefined" disabled>Choose type</option>
      <option value="Text">Text</option>
    </select>
    <div>
      <br />
      <MainButton
        :clickFunction="confirmAdd"
        title="Add field"
        styleClass="primary"
        :buttonStyle="{ width: '100%' }"
      />
    </div>
  </div>
</template>

<script>
import MainButton from '@/layout/MainButton.vue'
import axios from 'axios'

export default {
  components: { MainButton },
  data() {
    return {
      fieldName: '',
      fieldType: undefined
    }
  },
  methods: {
    confirmAdd() {
      axios
        .post('settings/addfield', {
          name: this.fieldName,
          type: this.fieldType
        })
        .then(function (response) {
          _this.fields = response.data
        })
        .catch(function (error) {
          console.log(error)
        })
      this.refresh()
    },
    refresh() {
      this.$router.go()
    },
  },
}
</script>

<style scoped src="@/assets/css/popup.css"></style>
