<template>
  <tr class="tableRow">
    <td>
      {{ field.name }}
    </td>
    <td>
      {{applString}}
    </td>
    <td>
      {{ field.type }}
    </td>
    <td></td>
  </tr>
</template>

<script>
export default {
  props: ['field'],
  computed: {
    applString() {
      let string = ''
      this.field.appl.forEach(el => {
        string += ', '+el.appl_on.capitalizeFirstLetter()
      });
      return string.substring(2)
    }
  }
}
</script>

<style>
</style>