<template>
  <div class="pageWrapper">
    <div class="topbar">
      <div style="display: flex">
        <H2>Custom Fields</H2>
      </div>
      <MainButton
        :clickFunction="openNewFieldPopup"
        title="Add field"
        styleClass="primary"
        :buttonStyle="{ marginLeft: '15px' }"
      />
    </div>
    <SearchInput placeholder="Search" v-model="searchString" />
    <Loader v-if="!fields" :color="'#444'" />
    <fragment v-else>
      <p v-if="!fields.length > 0" class="noOrdersText">
        No orders found with the currently selected filter settings.
      </p>
      <table v-else class="standardTable">
        <br />
        <tr class="standardTableHeader" v-if="fields.length != 0">
          <th>Name</th>
          <th>Applicable on</th>
          <th>Type</th>
          <th width="40%"></th>
        </tr>
        <FieldItem :key="i" :field="field" v-for="(field, i) in fields" />
      </table>
    </fragment>
  </div>
</template>

<script>
import axios from 'axios'
import FieldItem from '@/components/settings/customFields/FieldItem'
import Loader from '@/components/Loader'
import AddFieldPopup from '@/components/settings/customFields/AddFieldPopup'
import MainButton from '@/layout/MainButton'
import SearchInput from '@/layout/SearchInput'
import H2 from '@/layout/typography/H2'

export default {
  components: { FieldItem, Loader, AddFieldPopup, MainButton, SearchInput, H2 },
  data() {
    return {
      searchString: '',
      fields: undefined,
    }
  },
  created() {
    this.getFields()
  },
  methods: {
    getFields() {
      let _this = this
      axios
        .post('settings/getAllFields')
        .then(function (response) {
          _this.fields = response.data
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    openNewFieldPopup() {
      // this.$store.commit('SET_POPUP', AddFieldPopup)
    },
  },
}
</script>

<style scoped lang="scss">
</style>